@import '../../styles/_variaveis.scss';

.header {
    text-align: center;
    padding: 25px 35%;
    height: 15vh;
    background-color: $main;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 0 0 24px 24px;
    box-shadow: 0 0 60px black;
    box-sizing: border-box;

    div {
        .btn {
            background: none;
            border-radius: 15px;

            .iconContainer {
                display: block;
                width: 50px;

                .img {
                    color: aliceblue;
                }
            }
        }

        h3 {
            text-decoration: underline;
            margin: 5px;
        }
    }
}