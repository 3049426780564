@import '../../styles/variaveis';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 5%;


    label {
        color: $text;
        font-size: 24px;
        font-weight: bold;
    }

    input {
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        outline: none;
    }
}