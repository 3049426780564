@import '../../styles/_variaveis.scss';

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 25px;
    height: 15vh;
    background-color: $main;
    display: flex;
    justify-content: right;
    align-items: center;
    border-radius: 24px 24px 0 0;
    box-shadow: 0 0 60px black;
    box-sizing: border-box;

    .text{
        color: aliceblue;
        font-size: 15px;
    }

    .containerImg {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .img {
            width: 200px;
            margin: 0 45px;
        }
    }
}
