@import '../../styles/variaveis';

.link{
    padding: 15px;
    font-size: 25px;
    text-decoration: none;
    color: $realce;

    &:active{
        color: $RosaVibrante;
    }
}